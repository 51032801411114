<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="clipboard-list"
          :title="$idCurrency(sum.totalGI)"
          :cap="`Total GI`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="handshake-outline"
          color="green"
          :title="$idCurrency(sum.paidGI)"
          :cap="`Total GI Lunas`"
          :loading="reportLoading"
        />
      </v-col>
      <v-col cols="12" md="4">
        <molecules-summary-card
          icon="not-equal-variant"
          :title="$idCurrency(sum.unPaidGI)"
          :cap="`Total GI Belum Lunas`"
          :loading="reportLoading"
        />
      </v-col>
    </v-row>

    <v-form @submit.prevent="loadData" id="filter" class="mt-4">
      <atoms-text class="pa-2"><v-icon left>mdi-magnify</v-icon><span>Pencarian</span></atoms-text>
      <v-row justify="space-between">
        <v-col cols="12" md="10">
          <atoms-text-field
            label="Ketikkan nama perusahaan, sales atau manager..."
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                loadData()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col>
        <v-col cols="12" md="2" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Summary</atoms-button
          >
        </v-col>
        <v-col cols="12" md="6">
          <atoms-text-field label="View" :noLabel="true">
            <v-combobox
              class="rounded-lg"
              placeholder="View"
              prepend-inner-icon="mdi-swap-horizontal"
              :items="views.map((x) => $toCapitalize(x))"
              v-model="view"
              @change="loadData"
              hide-details
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </atoms-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <atoms-text-field label="Status" :noLabel="true">
            <v-combobox
              class="rounded-lg"
              placeholder="Status"
              prepend-inner-icon="mdi-filter-variant"
              :items="statuses"
              v-model="status"
              @change="loadData"
              hide-details
              dense
              solo
              flat
              required
              :style="{
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }"
            />
          </atoms-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="12" class="pb-md-0">
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" md="auto">
          <v-row dense align="center" justify="end">
            <v-col cols="12" md="auto">
              <atoms-text-field :noLabel="true">
                <v-combobox
                  class="rounded-lg"
                  placeholder="Sorter"
                  prepend-inner-icon="mdi-sort"
                  :items="sorters.map((x) => x.label)"
                  v-model="sorter"
                  @change="loadData"
                  hide-details
                  dense
                  solo
                  flat
                  required
                  :style="{
                    'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                  }"
                />
              </atoms-text-field>
            </v-col>
            <v-col cols="12" md="auto">
              <atoms-button
                class="primary"
                :style="{
                  width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
                }"
                @click="
                  () => {
                    sort = sort === constant[0] ? constant[1] : constant[0]
                    getMainItems()
                  }
                "
                ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
                >Sort: {{ sort }}</atoms-button
              ></v-col
            >
          </v-row>
        </v-col>
        <v-col cols="12" md="auto" class="grow d-flex justify-center justify-md-end align-center">
          <v-checkbox v-model="enableStartDate" hide-spin-buttons /><atoms-text
            >Filter berdasarkan tanggal Penerbitan Invoice
          </atoms-text>
          <div class="px-2"></div>
          <v-checkbox v-model="enableLastPaymentDate" hide-spin-buttons /><atoms-text
            >Filter berdasarkan tanggal Pembayaran Terakhir</atoms-text
          >
        </v-col>
        <!-- date -->
        <v-scroll-y-transition>
          <v-col cols="12" v-if="enableLastPaymentDate">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="pickerEndFromDialog"
                  v-model="invoiceFrom.modal"
                  :return-value.sync="invoiceFrom.value"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Dari Tanggal (Pembayaran Terakhir)">
                      <v-text-field
                        :loading="mainLoading"
                        class="rounded-lg"
                        placeholder="Dari Tanggal (Pembayaran Terakhir)"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatInvoiceFromDate"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="invoiceFrom.value"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerEndFromDialog.save(invoiceFrom.value)"
                  />
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="pickerEndToDialog"
                  v-model="invoiceTo.modal"
                  :return-value.sync="invoiceTo.value"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Sampai Tanggal (Pembayaran Terakhir)">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Sampai Tanggal"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatInvoiceToDate"
                        :loading="mainLoading"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        readonly
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="invoiceTo.value"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerEndToDialog.save(invoiceTo.value)"
                  />
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>

        <!-- date -->
        <v-scroll-y-transition>
          <v-col cols="12" v-if="enableStartDate">
            <v-row dense>
              <v-col cols="12" md="6">
                <v-dialog
                  ref="pickerFromDialog"
                  v-model="startFrom.modal"
                  :return-value.sync="startFrom.value"
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Dari Tanggal (Penerbitan Invoice)">
                      <v-text-field
                        :loading="mainLoading"
                        class="rounded-lg"
                        placeholder="Dari Tanggal"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatStartFromDate"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        readonly
                        dense
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="startFrom.value"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerFromDialog.save(startFrom.value)"
                  />
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6">
                <v-dialog ref="pickerToDialog" v-model="startTo.modal" :return-value.sync="startTo.value" width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <atoms-text-field label="Sampai Tanggal (Penerbitan Invoice)">
                      <v-text-field
                        class="rounded-lg"
                        placeholder="Sampai Tanggal (Penerbitan Invoice)"
                        append-icon="mdi-calendar"
                        background-color="accent"
                        v-model="formatStartToDate"
                        :loading="mainLoading"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        readonly
                        solo
                        flat
                        :style="{
                          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                        }"
                      />
                    </atoms-text-field>
                  </template>
                  <v-date-picker
                    :max="$moment().endOf('month').format('YYYY-MM-DD')"
                    v-model="invoiceFrom.value"
                    scrollable
                    class="rounded-lg"
                    @click:date="$refs.pickerToDialog.save(invoiceFrom.value)"
                  />
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-scroll-y-transition>
      </v-row>
    </v-form>

    <!-- core -->
    <v-container fluid class="px-0">
      <v-data-table
        class="rounded-lg"
        disable-sort
        hide-default-header
        hide-default-footer
        mobile-breakpoint="0"
        :headers="[
          {
            text: 'Nama Klien',
            class: 'text-left',
          },
          {
            text: 'Nama Sales',
            class: 'text-center',
          },
          {
            text: 'Nama Manager',
            class: 'text-center',
          },
          {
            text: 'Value GI Lunas',
            class: 'text-center',
          },
          {
            text: 'Value GI Belum Lunas',
            class: 'text-center',
          },
          {
            text: 'Total Value GI',
            class: 'text-center',
          },
          {
            text: 'Pembayaran Terakhir',
            class: 'text-right',
          },
        ]"
        :loading="mainLoading"
        :items="(!mainLoading && $store.state.salesb2b.data) || []"
      >
        <template v-slot:[`header`]>
          <thead>
            <tr class="d-table-row">
              <th :class="['text-left', view && view.toLowerCase() !== views[0] && 'd-none']">
                <span class="primary--text text-uppercase">Nama Klien</span>
              </th>
              <th :class="['text-center', view && view.toLowerCase() === views[2] && 'd-none']">
                <span class="primary--text text-uppercase">Nama Sales</span>
              </th>
              <th :class="['text-center', view && view.toLowerCase() === views[1] && 'd-none']">
                <span class="primary--text text-uppercase">Nama Manager</span>
              </th>
              <th :class="['text-center']">
                <span class="primary--text text-uppercase">Value GI Lunas</span>
              </th>
              <th :class="['text-center']">
                <span class="primary--text text-uppercase">Value GI Belum Lunas</span>
              </th>
              <th :class="['text-center']">
                <span class="primary--text text-uppercase">Total Value GI</span>
              </th>
              <th :class="['text-right', view && view.toLowerCase() !== views[0] && 'd-none']">
                <span class="primary--text text-uppercase">Pembayaran Terakhir</span>
              </th>
            </tr>
          </thead></template
        >

        <template v-slot:[`item`]="{ item }">
          <tr class="d-table-row text-left">
            <td v-if="view && view.toLowerCase() === views[0]">
              {{ (item.client && item.client.name) || '-' }}
            </td>
            <!-- item.areaId -->
            <td v-if="view && view.toLowerCase() !== views[2]" class="text-center">
              <template v-if="item.salesRepData && item.salesRepData.length > 0">
                <v-chip v-for="(x, i) in item.salesRepData || []" :key="i" class="rounded-lg ma-1" small
                  ><v-avatar v-if="x.picture" class="rounded" left><atoms-image :src="x.picture" /></v-avatar
                  >{{ x.nickname }}</v-chip
                >
              </template>
              <span v-else>-</span>
            </td>

            <td v-if="view && view.toLowerCase() !== views[1]" class="text-center">
              <v-chip v-if="item.manager" class="rounded-lg my-1" small
                ><v-avatar v-if="item.manager.picture" class="rounded" left
                  ><atoms-image :src="item.manager.picture" /></v-avatar
                >{{ item.manager.nickname }}</v-chip
              >
              <span v-else>-</span>
            </td>
            <td class="text-center" style="white-space: nowrap">
              {{ item.paidGI ? $idCurrency(item.paidGI) : '-' }}
            </td>
            <td class="text-center" style="white-space: nowrap">
              {{ item.unPaidGI ? $idCurrency(item.unPaidGI) : '-' }}
            </td>
            <td class="text-center" style="white-space: nowrap">
              {{ item.totalGI ? $idCurrency(item.totalGI) : '-' }}
            </td>
            <!-- <td
              v-if="view && view.toLowerCase() === views[0]"
              class="text-right"
            >
              {{
                item.lastPaymentDate
                  ? $moment(item.lastPaymentDate).format("DD MMMM YYYY")
                  : "-"
              }}
            </td> -->
            <td v-if="view && view.toLowerCase() === views[0]" class="text-right">
              {{ item.lastPaymentDate ? $moment(item.lastPaymentDate).format('DD MMMM YYYY') : '-' }}
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-row
        justify="center"
        v-if="$store.state.salesb2b.data && $store.state.salesb2b.data.length > 0 && $store.state.salesb2b.pagesLength"
      >
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination class="my-4" v-model="page" :length="$store.state.salesb2b.pagesLength"></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
const sortConstant = [
  {
    label: 'Sales',
    value: 'sales.nickname',
  },
  {
    label: 'Klien',
    value: 'client.name',
  },
  {
    label: 'Pembayaran Terakhir',
    value: 'invoices.lastPaymentDate',
  },
  {
    label: 'GI Lunas',
    value: 'paidGI',
  },
  {
    label: 'GI Belum Lunas',
    value: 'unPaidGI',
  },
  {
    label: 'Total GI',
    value: 'totalGI',
  },
]
const dateFormat = 'YYYY-MM-DD[T00:00:00.000Z]'
export default {
  data() {
    return {
      term: '',
      page: 1,
      limit: 10,
      reportLoading: false,
      mainLoading: false,
      enableStartDate: false,
      enableLastPaymentDate: true,
      sort: '',
      constant: ['Desc', 'Asc'],
      expandedIds: [],
      view: '',
      views: ['client view', 'sales view', 'manager view'],
      sorter: '',
      sorters: sortConstant,
      status: null,
      sum: {},
      statuses: Object.keys(this.$salesStatus)?.map((key, val) => this.$salesStatus?.[key]),
      enableDate: false,
      startFrom: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      startTo: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      invoiceFrom: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      invoiceTo: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
    }
  },
  watch: {
    enableStartDate: function (newValue) {
      this.onlyQuery()
    },
    enableLastPaymentDate: function (newValue) {
      this.onlyQuery()
    },
    'startFrom.value': function (newValue) {
      if (newValue) {
        this.onlyQuery()
      }
    },
    'startTo.value': function (newValue) {
      if (newValue) {
        this.onlyQuery()
      }
    },
    'invoiceFrom.value': function (newValue) {
      if (newValue) {
        this.onlyQuery()
      }
    },
    'invoiceTo.value': function (newValue) {
      if (newValue) {
        this.onlyQuery()
      }
    },
    page: {
      handler() {
        this.getMainItems()
      },
      deep: true,
    },
  },
  computed: {
    summary() {
      return this.$store.state.salesb2b?.summary
    },
    formatStartFromDate: {
      set(value) {
        this.startFrom.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.startFrom.value).format('DD MMMM YYYY')
      },
    },
    formatStartToDate: {
      set(value) {
        this.startTo.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.startTo.value).format('DD MMMM YYYY')
      },
    },
    formatInvoiceFromDate: {
      set(value) {
        this.invoiceFrom.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.invoiceFrom.value).format('DD MMMM YYYY')
      },
    },
    formatInvoiceToDate: {
      set(value) {
        this.invoiceTo.value = this.$moment(value).format('YYYY-MM-DD')
      },
      get() {
        return this.$moment(this.invoiceTo.value).format('DD MMMM YYYY')
      },
    },
  },
  async mounted() {
    this.sort = this.constant[0]
    this.sorter = sortConstant[2]?.label
    this.status = this.$salesStatus.all
    this.view = this.$toCapitalize(this.views[0])
    this.loadData()
  },
  methods: {
    onlyQuery() {
      this.getMainItems()
    },
    async loadData() {
      this.page = 1
      //console.log(this.getFilterMongo());
      this.getMainItems()
      this.$vuetify.goTo(0)
    },
    getFilterInvoice() {
      // filter date
      const invoiceDate = this.enableStartDate
        ? {
            $gte: this.$moment(this.startFrom.value).format(dateFormat),
            $lte: this.$moment(this.startTo.value || new Date()).format(dateFormat),
          }
        : undefined
      const lastPaymentDate = this.enableLastPaymentDate
        ? {
            $gte: this.$moment(this.invoiceFrom.value).format(dateFormat),
            $lte: this.$moment(this.invoiceTo.value || new Date()).format(dateFormat),
          }
        : undefined
      return {
        lastPaymentDate,
        date: invoiceDate,
        isDeleted: { $ne: true },
      }
    },
    pipeline() {
      const term = this.term
        ? {
            $regex: this.term || '',
            $options: 'i',
          }
        : undefined
      // filter status
      const totalGI =
        this.status?.toLowerCase() === this.$salesStatus.paid
          ? {
              $ne: 0,
            }
          : this.status?.toLowerCase() === this.$salesStatus.outstanding
          ? {
              $ne: 0,
            }
          : undefined
      const unPaidGI =
        this.status?.toLowerCase() === this.$salesStatus.paid
          ? {
              $gt: -1,
              $lt: 1,
            }
          : this.status?.toLowerCase() === this.$salesStatus.outstanding
          ? {
              $ne: 0,
            }
          : undefined
      // filter invoice
      const filterInvoice = this.getFilterInvoice()
      // query
      const filterMongo = {
        klien: undefined,
        emailPIC: term,
        pipeline: [
          {
            $lookup: {
              from: 'Clients',
              localField: 'klien',
              foreignField: '_id',
              as: 'client',
            },
          },
          {
            $match: {
              'client.name': term,
            },
          },
          {
            $unwind: {
              path: '$client',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: 'Users',
              localField: 'salesRep',
              foreignField: '_id',
              as: 'salesRepData',
            },
          },
          {
            $match: {
              'salesRepData.nickname': term,
            },
          },
          {
            $lookup: {
              from: 'Users',
              localField: 'managerId',
              foreignField: '_id',
              as: 'manager',
            },
          },
          {
            $match: {
              'manager.nickname': term,
            },
          },
          {
            $unwind: {
              path: '$manager',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: 'Invoices',
              as: 'invoices',
              let: { clientId: '$klien' },
              pipeline: [
                {
                  $match: {
                    $expr: { $eq: ['$clientId', '$$clientId'] },
                    ...filterInvoice,
                  },
                },
              ],
            },
          },
          // {
          //   $match: {
          //     invoices: { $ne: [] },
          //   },
          // },
          {
            $project: {
              'salesRepData._id': 1,
              'salesRepData.picture': 1,
              'salesRepData.nickname': 1,
              'client._id': 1,
              'client.logo': 1,
              'client.name': 1,
              'manager._id': 1,
              'manager.picture': 1,
              'manager.nickname': 1,
              // invoices: 1,
              'invoices.paymentProcess': 1,
              'invoices.lastPaymentDate': 1,
              'invoices.valueFee': 1,
              'invoices.valueGI': 1,
              'invoices.paidGI': 1,
              'invoices.unPaidGI': 1,
              totalGI: { $sum: '$invoices.valueGI' },
              paidGI: { $sum: '$invoices.paidGI' },
              unPaidGI: { $sum: '$invoices.unPaidGI' },
            },
          },
          {
            $match: {
              totalGI: { $gt: 0 },
            },
          },
          {
            $match: {
              totalGI: totalGI,
              unPaidGI: unPaidGI,
            },
          },
          {
            $sort: Object.assign(
              {},
              (() => {
                const finder = this.sorters?.find((x) => x.label === this.sorter)?.value
                return finder
                  ? {
                      [finder]: this.sort === this.constant[0] ? -1 : 1,
                    }
                  : {
                      _createdDate: this.sort === this.constant[0] ? -1 : 1,
                    }
              })(),
            ),
          },
        ],
      }
      // step view
      if (this.view.toLowerCase() === this.views[1]) {
        // sales
        filterMongo.pipeline.push({
          $group: {
            _id: '$salesRepData._id',
            salesRepData: { $first: '$salesRepData' },
            totalGI: { $sum: '$totalGI' },
            paidGI: { $sum: '$paidGI' },
            unPaidGI: { $sum: '$unPaidGI' },
          },
        })
      }
      if (this.view.toLowerCase() === this.views[2]) {
        // manager
        filterMongo.pipeline.push({
          $group: {
            _id: '$manager._id',
            manager: { $first: '$manager' },
            totalGI: { $sum: '$totalGI' },
            paidGI: { $sum: '$paidGI' },
            unPaidGI: { $sum: '$unPaidGI' },
          },
        })
      }
      return filterMongo
    },
    async getSum() {
      const filterInvoice = this.getFilterInvoice()
      const jsonQuery = {
        jsonQuery: JSON.stringify(filterInvoice),
      }
      const query = this.$queryString.stringify(jsonQuery)
      this.reportLoading = true
      try {
        this.sum = await this.$api.get(`Invoices/getSum/?${query}`).then(({ result }) => result)
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.reportLoading = false
      }
    },
    async getMainItems() {
      await this.getSum()
      this.mainLoading = true
      try {
        const result = await this.$store.dispatch('salesb2b/getAll', {
          custom: this.pipeline(),
          page: this.page,
          limit: this.limit,
        })
        // calculating sales summary
        const data = result?.map((it) => {
          const invoices = it?.invoices || []
          const lastPaymentDate = invoices
            ?.map((inv) => inv?.paymentProcess?.[0]?.date)
            ?.filter((item) => item)
            ?.sort((a, b) => new Date(b) - new Date(a))?.[0]
          return {
            ...it,
            lastPaymentDate,
          }
        })
        this.$store.commit('salesb2b/SET', {
          data,
        })
      } catch (err) {
        Promise.reject(err)
      }
      this.mainLoading = false
    },
  },
}
</script>
